import {Popup} from './popup'
import './jquery.inputmask.bundle'

var User = function(){
  this.newSessionBlock    = $('.login-block-js, .pass-recovery-js');
  this.registrationBlock  = $('.registration-form-js');
  this.newSessionButton         = this.newSessionBlock.find('[name=commit]');
  this.submitRegistrationButton = this.registrationBlock.find('[name=commit]');
  this.email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  this.initializeEvents();
};
User.prototype.initializeEvents = function(){
  var self = this;

  this.newSessionButton.on('click', function(event){
    if (!self.newSessionFormValid()) {
      event.preventDefault();
    }
  });
  this.submitRegistrationButton.on('click', function(event){
    event.preventDefault();
    var form = this.form;
    if (self.registrationFormValid()){
      form.submit();
    }
  });
  if(this.registrationBlock.length) self.initMask();
};

// User.prototype.initializeEvents = function(){
//   var self = this;

//   this.newSessionButton.on('click', function(event){
//     if (!self.newSessionFormValid()) {
//       event.preventDefault();
//     }
//   });
//   this.submitRegistrationButton.on('click', function(event){
//     event.preventDefault();
//     var form = this.form;
//     if (self.registrationFormValid()){
//       grecaptcha.execute(reCAPTCHA_site_key, { action: 'registrations' }).then(function (token) {
//         // Генерируется во вьюхе с помощью recaptcha_v3
//         // надо получать свежий токен перед отправкой формы, так
//         // как старый мог протухнуть
//         setInputWithRecaptchaResponseTokenForRegistrations('g-recaptcha-response-data-registrations', token)
//         form.submit();
//       }, function (reason) {
//         console.log(reason);
//       });
//     }
//   });
//   if(this.registrationBlock.length) self.initMask();
// };

User.prototype.newSessionFormValid = function(){
  if (!this.validateEmail(this.newSessionBlock)) { return false; }
  if (!this.validatePasswordPresence(this.newSessionBlock)) { return false; }

  return true;
};

User.prototype.validatePasswordPresence = function(container){
  var passw = container.find('[name="user[password]"]');

  if (!!passw.length){
    if (passw.val().length == 0){
      new Popup('error', I18n.t('user_password_required')).show();
      return false;
    }
  }

  return true
};

User.prototype.validateEmail = function(container){
  var email = container.find('[name="user[email]"]');

  if (!!email.length){
    if (email.val().length == 0){
      new Popup('error', I18n.t('user_email_required')).show();
      return false;
    }
    if (!this.email_regex.test(email.val())){
      new Popup('error', I18n.t('user_email_email')).show();
      return false;
    }
  }
  return true;
};

User.prototype.registrationFormValid = function(){
  var passw = this.registrationBlock.find('[name="user[password]"]');
  var agree = this.registrationBlock.find('[name="user[read_contract_form]"]');
  var phone = this.registrationBlock.find('[name="user[phone]"]');
  var bonus_program = this.registrationBlock.find('[name="user[bonus_program]"]');
  var first_name = this.registrationBlock.find('[name="user[first_name]"]').val();
  var last_name = this.registrationBlock.find('[name="user[last_name]"]').val();

  if (!this.validateEmail(this.registrationBlock)) { return false; }
  if (!this.validatePasswordPresence(this.registrationBlock)) { return false; }

  if (passw.val().length < 8){
    new Popup('error', I18n.t('user_password_min_length')).show();
    return false;
  }
  if (!!agree.length && !agree.is(':checked')){

    new Popup('error', agree.hasClass('disable_oferta') ? I18n.t('iagree_no_oferta') : I18n.t('iagree')).show();
    return false;
  }
  if (phone.val().length < phone[0].minLength){
    new Popup('error', I18n.t('phone_valid')).show();
    return false;
  }
  if($('#student').is(':checked')){
    var passport   = new Validation('passport', this.registrationBlock.find('[name="user[student]"]'));
    if (!passport.isValid()){
      passport.showError();
      return indicator = false;
    }
  }
  // if(this.registrationBlock.find('.new_user').length && this.registrationBlock.find('[name=g-recaptcha-response]').length && !this.registrationBlock.find('[name=g-recaptcha-response]').val()){
  //   new Popup('error', I18n.t('waiting_list.form.validate.recaptcha.required')).show();
  //   return false;
  // }
  if (!!bonus_program.length && bonus_program.is(':checked')){  
    if(phone.val() == '' || first_name == '' || last_name == '') {
      new Popup('error', I18n.t('bonus_program')).show();
      return false;
    }
  }  
  return true;
};

User.prototype.initMask = function(){
  var self = this;
  var phone_number = self.registrationBlock.find('[name="user[phone_number]"]');
  $(phone_number).inputmask({ 
                              mask: phone_number.data('mask'), 
                              autoUnmask: true
                            });    
};

// $(document).on('turbolinks:load', function () {
//   new User();
// });

export default User